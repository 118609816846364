let bW = document.documentElement.clientWidth,
  bH = document.documentElement.clientHeight,
  scrollTop = window.pageYOffset,
  previousTop = 0,
  tabParam = {};

tabParam.isScrollingUp = false;
tabParam.swipingStart = false;
tabParam.isSwipingTab = false;
tabParam.swipingPos = {
  orig: 0,
  curr: 0
};
tabParam.width = 0;
tabParam.left = 0;
const topTags = document.querySelector('.p-top-access__tags--longer');

let isSmall = false;
if (bW < 600) isSmall = true;
//img.retina要素のsrcsetを記述
jQuery("img.retina").each(function (i, e) {
  var srcName = $(this).attr('src');
  if (srcName.indexOf('.svg') == -1) { //SVG以外のimg要素を選ぶ
    var srcSetTxt = srcName + " 1x," + srcName.replace(/(\.gif|\.jpg|\.png)/g, '@2x$1') + " 2x";
    srcName = $(this).attr('srcset', srcSetTxt);
  }
});
window.onload = () => {
  console.log('onload');
  setHam();
  setSlider();
  setTabs();
  moveMoon();
  if (topTags) setTopTags();
}
window.addEventListener('resize', function () {
  bW = document.documentElement.clientWidth;
  bH = document.documentElement.clientHeight;
  if (bW < 600) {
    isSmall = true;
  } else {
    isSmall = false;
  }
  if (topTags) setTopTags();
});

window.onscroll = () => {
  scrollTop = window.pageYOffset;
  moveMoon();
  setHeader();
}

const setHam = () => {
  console.log('init');
  const ham = document.querySelector('.c-ham a');
  ham.addEventListener('click', (e) => {
    e.preventDefault();
    if (ham.classList.contains('is-active')) {
      ham.classList.remove('is-active');
      document.body.classList.remove('menu-mode');
    } else {
      ham.classList.add('is-active');
      document.body.classList.add('menu-mode');
    }
    return false;
  })
  const close = document.querySelector('.c-nav__close a');
  close.addEventListener('click', (e) => {
    e.preventDefault();
    ham.classList.remove('is-active');
    document.body.classList.remove('menu-mode');
    return false;
  })

  const parMenu = document.querySelectorAll(".has-submenu > a");

  for (const elm of parMenu) {
    elm.addEventListener('click', setAccordion);
  }
}

const setAccordion = (e) => {
  if (bW < 768) {
    e.preventDefault();
    console.log(e.target)
    let target = e.target.closest('.c-nav__item');
    target.classList.toggle('is-open')
  }
}
const setHeader = () => {
  if (scrollTop > 150) {
    document.body.classList.add('is-scrolled')
    if (!tabParam.isScrollingUp && scrollTop < previousTop) {
      tabParam.isScrollingUp = true;
      console.log("UP")
      document.body.classList.add('header-mode');
    } else if (tabParam.isScrollingUp && scrollTop > previousTop) {
      tabParam.isScrollingUp = false;
      console.log("down")
      document.body.classList.remove('header-mode');
    }
  } else if (scrollTop <= 0) {
    document.body.classList.remove('is-scrolled')
  }
  previousTop = scrollTop;
}

const setSlider = () => {
  const slides = {
    topMV: '.p-top-mv__sliderContainer',
    topADs: '.p-top-slider__list'
  }
  if (document.querySelector(slides.topMV)) {
    const topMV = new Swiper(slides.topMV, {
      loop: true,
      effect: "fade",
      allowTouchMove: false,
      autoplay: {
        delay: 5000,
      }
    })
  }
  if (document.querySelector(slides.topADs)) {
    const topADs = new Swiper(slides.topADs, {
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    })
  }
}
const setTabs = () => {
  let hashIndex = null;
  const topTabs = document.querySelector('.p-top-tabs');
  if (topTabs) {
    const tabs = topTabs.querySelectorAll('[role="tab"]');
    let counter = 0;
    tabLength = tabs.length;

    for (const elm of tabs) {
      tabParam.width += elm.clientWidth;
      console.log(tabParam.width)
      elm.addEventListener('mouseup', tabClickListener);
      elm.addEventListener('mousedown', tabSwipeStartListener);
      window.addEventListener('mousemove', tabSwipeListener);
      elm.addEventListener('touchend', tabClickListener);
      window.addEventListener('touchmove', tabSwipeListener);
      elm.addEventListener('touchstart', tabSwipeStartListener);
      elm.addEventListener('keydown', tabKeydownListener);
      elm.addEventListener('keyup', tabKeyupListener);
      tabs[counter].index = counter;
      tabs[counter].width = elm.clientWidth;
      if (`#${tabs[counter].id}` == window.location.hash) {
        hashIndex = counter;
      }
      counter++;
    }
    tabParam.lastItemWidth = tabs[tabs.length - 1].width;
  }
  if (hashIndex) setTabPanel(hashIndex);
}

const tabClickListener = (e) => {
  var tab = e.target;
  if (tab.getAttribute('role') != "tab") {
    tab = tab.parentNode;
  }
  if (!tabParam.isSwipingTab) setTabPanel(tab.index);
  if (tabParam.isSwipingTab) tabParam.left = tabParam.currLeft;
  tabParam.swipingStart = false;
  tabParam.isSwipingTab = false;
}
const tabSwipeStartListener = (e) => {
  e.preventDefault()
  document.querySelector('.p-top-tabs').classList.remove('is-animated');
  tabParam.swipingPos.orig = e.touches ? e.touches[0].screenX : e.screenX;
  console.log(tabParam.swipingPos.orig)
  tabParam.swipingStart = true;
}
const tabSwipeListener = (e) => {
  if (tabParam.swipingStart) {
    tabParam.swipingPos.curr = e.touches ? e.touches[0].screenX : e.screenX;
    tabParam.diff = tabParam.swipingPos.curr - tabParam.swipingPos.orig;
    if (Math.abs(tabParam.diff) > 20) {
      tabParam.isSwipingTab = true;
      let left = tabParam.diff + tabParam.left > 0 ? 0 : tabParam.diff + tabParam.left < 0 - tabParam.width + tabParam.lastItemWidth ? 0 - tabParam.width + tabParam.lastItemWidth : tabParam.diff + tabParam.left;
      document.querySelector('.p-top-tabs__tab-list').style.left = `${left}px`;
      tabParam.currLeft = left;
    }
    return false;
  }
}

const tabKeydownListener = (e) => {
  var key = e.keyCode;
  //console.log(`down${key}`)
  switch (key) {
    case tabKeys.end:
    case tabKeys.home:
    case tabKeys.up:
    case tabKeys.left:
    case tabKeys.down:
    case tabKeys.right:
      e.preventDefault();
      break;
  }
}

const tabKeyupListener = (e) => {
  var key = e.keyCode
  //console.log(`up${key}`);
  let target = 0;
  switch (key) {
    case tabKeys.end:
      e.preventDefault();
      // Activate last tab
      setTabPanel(tabs[tabLength - 1]);
      break;
    case tabKeys.home:
      e.preventDefault();
      // Activate first tab
      setTabPanel(tabs[0]);
      break;

      // Up and down are in keydown
      // because we need to prevent page scroll >:)
    case tabKeys.up:
    case tabKeys.left:
      e.preventDefault();
      target = tabIndex - 1;
      if (target < 0) target = tabLength - 1;
      setTabPanel(target);
      break;
    case tabKeys.down:
    case tabKeys.right:
      e.preventDefault();
      target = tabIndex + 1;
      if (target > tabLength - 1) target = 0;
      setTabPanel(target);
      break;
  }
}
let tabLength, tabIndex = 0;
const tabKeys = {
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46,
};
const tabDirection = {
  37: -1,
  38: -1,
  39: 1,
  40: 1,
};

const setTabPanel = (index) => {
  const topTabs = document.querySelector('.p-top-tabs');
  const tabs = topTabs.querySelectorAll('[role="tab"]');
  const panels = topTabs.querySelectorAll('[role="tabpanel"]');
  const currentTab = topTabs.querySelector('[aria-selected="true"]');
  const currentPanel = topTabs.querySelector('[role="tabpanel"]:not([hidden="hidden"])');
  if (index != currentTab.index) {
    currentTab.setAttribute('tabindex', '-1');
    currentTab.setAttribute('aria-selected', 'false');
    currentPanel.setAttribute('hidden', 'hidden');
    tabs[index].removeAttribute('tabindex');
    tabs[index].setAttribute('aria-selected', 'true');
    panels[index].removeAttribute('hidden');
    tabIndex = index;
    //console.log(tabLength + " | " + tabIndex)
    if (bW < 600) {
      let sum = 0;
      for (let i = 0; i < tabIndex; i++) {
        sum += tabs[i].width;
      }
      document.querySelector('.p-top-tabs').classList.add('is-animated');
      clearTimeout(tabParam.timeout)
      tabParam.timeout = setTimeout(() => {
        document.querySelector('.p-top-tabs').classList.remove('is-animated');
      }, 400)
      document.querySelector('.p-top-tabs__tab-list').style.left = `${-1 * sum}px`;
      tabParam.left = -1 * sum;
    }
  }

}

const setTopTags = () => {
  const orig = document.querySelector('.p-top-access__tags--longer');
  const target = document.querySelector('.p-top-access__tags--shorter');
  if (bW > 780) target.style.height = `${orig.clientHeight}px`;
}

const moveMoon = () => {
  const area = document.querySelector('.c-foot-info__area');
  const moon = document.querySelector('.c-foot-info__moon');
  let progress = 1 - moon.getBoundingClientRect().top / bH;
  if (bH < 768) {
    progress *= 2.5;
  }
  let degree = -20;
  if (area.getBoundingClientRect().top < bH) {
    degree = -1 * (1 - Math.atan(progress * 2.5) * 2 / Math.PI) * 20;
    moon.style.transform = `rotate(${degree}deg)`;
    moon.style.opacity = progress * 3 / 4 + .25;
  }
}